import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/design/design/node_modules/@primer/gatsby-theme-doctocat/src/components/layout.js";
import { ComponentPageNav } from '~/src/components/component-page-nav';
import StatusMenu from '~/src/components/status-menu';
import { PropsTable } from '~/src/components/props-table';
import { AccessibilityLabel, StatusLabel } from '@primer/gatsby-theme-doctocat';
import { Label } from '@primer/react';
import { graphql } from "gatsby";
export const query = graphql`
  query {
    primerReactVersion {
      version
    }
    allReactComponent(filter: {componentId: {eq: "button"}}) {
      nodes {
        status
      }
    }
  }
`;
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Box = makeShortcode("Box");
const layoutProps = {
  query,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <Box style={{
      marginBottom: "24px"
    }} mdxType="Box">
  <ComponentPageNav basePath="/components/button" includeReact={true} includeRails={true} includeFigma={true} includeCSS={true} current="react" mdxType="ComponentPageNav" />
    </Box>
    <Box style={{
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      marginBottom: "25px"
    }} mdxType="Box">
  <Box style={{
        display: "flex",
        gap: "5px"
      }} mdxType="Box">
    <Label size="large" mdxType="Label">@primer/react@v{props.data.primerReactVersion.version}</Label>
    <StatusLabel status="Deprecated" mdxType="StatusLabel" />
    <AccessibilityLabel a11yReviewed={false} short={false} mdxType="AccessibilityLabel" />
  </Box>
  <Box mdxType="Box">
    <StatusMenu currentStatus="deprecated" statuses={[...props.data.allReactComponent.nodes.map(reactComponent => reactComponent.status), "deprecated"]} parentPath="/components/button/react" mdxType="StatusMenu" />
  </Box>
    </Box>
    <h2>{`Deprecation`}</h2>
    <p>{`Use `}<a parentName="p" {...{
        "href": "/components/button/react"
      }}>{`the new Button component`}</a>{` instead.`}</p>
    <p><inlineCode parentName="p">{`Button`}</inlineCode>{` is used for actions, like in forms, while `}<inlineCode parentName="p">{`Link`}</inlineCode>{` is used for destinations, or moving from one page to another.`}</p>
    <p>{`In special cases where you'd like to use a `}<inlineCode parentName="p">{`<a>`}</inlineCode>{` styled like a Button, use `}<inlineCode parentName="p">{`<Button as='a'>`}</inlineCode>{` and provide an `}<inlineCode parentName="p">{`href`}</inlineCode>{`.`}</p>
    <p>{`To create a button group, wrap `}<inlineCode parentName="p">{`Button`}</inlineCode>{` elements in the `}<inlineCode parentName="p">{`ButtonGroup`}</inlineCode>{` element. `}<inlineCode parentName="p">{`ButtonGroup`}</inlineCode>{` gets the same props as `}<inlineCode parentName="p">{`Box`}</inlineCode>{`.`}</p>
    <h2>{`Examples`}</h2>
    <h3>{`Kitchen sink`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "live deprecated",
        "live": true,
        "deprecated": true
      }}>{`<>
  <Button>Button</Button>
  <ButtonDanger>Button danger</ButtonDanger>
  <ButtonOutline>Button outline</ButtonOutline>
  <ButtonPrimary>Button primary</ButtonPrimary>
  <ButtonInvisible>Button invisible</ButtonInvisible>
  <ButtonClose onClick={() => window.alert('button clicked')} />

  <ButtonGroup display="block" my={2}>
    <Button>Button</Button>
    <Button>Button</Button>
    <Button>Button</Button>
  </ButtonGroup>

  <ButtonTableList>Button table list </ButtonTableList>
</>
`}</code></pre>
    <h2>{`Props`}</h2>
    <p>{`Native `}<inlineCode parentName="p">{`<button>`}</inlineCode>{` HTML attributes are forwarded to the underlying React `}<inlineCode parentName="p">{`button`}</inlineCode>{` component and are not listed below.`}</p>
    <PropsTable mdxType="PropsTable">
  <PropsTable.Row name="as" type="String" defaultValue="button" description="Sets the HTML tag for the component" />
  <PropsTable.Row name="sx" type="SystemStyleObject" defaultValue="{}" description="Additional styles" />
  <PropsTable.Row name="variant" type="String" defaultValue="'medium'" description="A value of `small`, `medium`, or `large` results in smaller or larger Button text size; no effect if `fontSize` prop is set" />
    </PropsTable>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      